// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk"
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import authService from "../oauth/auth-service"
import { LDConfig } from "../utils/launchdarkly"

const LaunchDarklyContext = createContext()

export const LaunchDarklyProvider = ({ children, ldClient }) => {
  const LDWrapper = withLDProvider({ ...LDConfig, ldClient: ldClient })(
    LDUserProvider
  )
  return <LDWrapper>{children}</LDWrapper>
}

const LDUserProvider = ({ children }) => {
  const ldClient = useLDClient()
  const [hasFetchedUserFlags, setHasFetchedUserFlags] = useState(false)

  const ldIdentify = useCallback(async () => {
    if (!ldClient || hasFetchedUserFlags) {
      return
    }

    const user = await authService.getUser()
    const account = authService.getAccountFromToken(user.access_token)
    const context = {
      kind: "user",
      key: account?.applicationCustomerId,
      email: user.profile.email,
      applicationCustomerId: account?.applicationCustomerId,
    }
    // Call client.identify with the userContext to identify the user
    await ldClient.identify(context).catch((error) => {
      console.error("Error identifying Launchdarkly user:", error)
    })
    setHasFetchedUserFlags(true)
  }, [hasFetchedUserFlags, ldClient])

  const memoizedContextValue = useMemo(
    () => ({
      hasFetchedUserFlags,
      ldIdentify,
    }),
    [hasFetchedUserFlags, ldIdentify]
  )

  return (
    <LaunchDarklyContext.Provider value={memoizedContextValue}>
      {children}
    </LaunchDarklyContext.Provider>
  )
}

export const useLDIdentify = () => {
  const context = useContext(LaunchDarklyContext)
  if (!context) {
    throw new Error("useLDIdentify must be used within a LaunchDarklyProvider")
  }
  return context
}
