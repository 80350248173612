// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { allowedPaths, getAllowedPaths } from "./routes/allowed-paths"
import { assetPaths } from "./utils/env"

const {
  REACT_APP_ALLOW_DENY_MANAGER_SRC: allowDenyManagerSrc,
  REACT_APP_ANNOUNCEMENTS_SRC: announcementsSrc,
  REACT_APP_APOLLO_OBJECT_SRC: apolloObjectSrc,
  REACT_APP_ATLAS_SRC: atlasSrc,
  REACT_APP_AUDIT_SRC: auditSrc,
  REACT_APP_BLOCK_SRC: blockSrc,
  REACT_APP_DATA_ACCESS_SRC: dataAccessSrc,
  REACT_APP_DATA_OBSERVABILITY_SRC: dataObservabilitySrc,
  REACT_APP_DOM_SRC: fleetSrc,
  REACT_APP_DUAL_AUTH_SRC: dualAuthSrc,
  REACT_APP_EMAIL_SRC: emailSrc,
  REACT_APP_FILE_DOM_SRC: fileDomSrc,
  REACT_APP_FILE_MANAGER_SRC: fileManagerSrc,
  REACT_APP_GROUPS_SRC: groupsSrc,
  REACT_APP_HOMEFLEET_SRC: homefleetSrc,
  REACT_APP_ISSUES_SRC: issuesSrc,
  REACT_APP_KUBERNETES_SERVICE_SRC: kubernetesServiceSrc,
  REACT_APP_NIMBLE_SRC: nimbleSrc,
  REACT_APP_OBJECT_STORAGE_SRC: objectStorageSrc,
  REACT_APP_PCAI_SRC: pcaiSrc,
  REACT_APP_PCBE_SRC: pcbeSrc,
  REACT_APP_PRIMERA_SRC: primeraSrc,
  REACT_APP_SDS_SRC: sdsSrc,
  REACT_APP_SEARCH_SRC: searchSrc,
  REACT_APP_SECRETS_SRC: secretsSrc,
  REACT_APP_SETUP_SRC: setupSrc,
  REACT_APP_SFM_SRC: sfmSrc,
  REACT_APP_SHAPESHIFTER_SRC: shapeshifterSrc,
  REACT_APP_STATUS_SRC: statusSrc,
  REACT_APP_TASKS_SRC: tasksSrc,
  REACT_APP_WEBHOOKS_SRC: webhooksSrc,
  REACT_APP_ZERTO_SRC: zertoSrc,
} = assetPaths

function extractBasePath(source) {
  try {
    return new URL(source).pathname
  } catch {
    return ""
  }
}

export const routes = {
  launchpad: {
    key: "launchpad",
    navPath: "/",
    path: "/",
    type: "internal",
  },
  "allow-deny-manager": {
    basePath: extractBasePath(allowDenyManagerSrc),
    key: "allow-deny-manager",
    navPath: "/allow-deny-manager",
    path: "/allow-deny-manager/*",
    resources: ["allow-entries.read", "deny-entries.read", "deny-reasons.read"],
    src: allowDenyManagerSrc,
    title: "titleAllowDenyManager",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["allow-deny-manager"],
      src: allowDenyManagerSrc,
    }),
  },
  announcements: {
    basePath: extractBasePath(announcementsSrc),
    flag: "shell-enable-announcements-1",
    key: "announcements",
    navPath: "/announcements",
    path: "/announcements/*",
    resources: ["announcement.read"],
    src: announcementsSrc,
    title: "titleAnnouncements",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["announcements"],
      src: announcementsSrc,
    }),
  },
  atlas: {
    basePath: extractBasePath(atlasSrc),
    key: "atlas",
    navPath: "/backup-and-recovery",
    path: "/backup-and-recovery/*",
    resources: [
      "app-data-management-dashboard.read",
      "app-data-management-job.read",
      "app-data-management-on-prem-engine.read",
      "app-data-management-template.read",
      "application-host.read",
      "application-server.read",
      "catalyst-gateway.read",
      "copy-pool.read",
      "csp-k8s-application-backup.read",
      "csp-rds-instance.read",
      "database-schedule-job.read",
      "database-snapshot.read",
      "database.read",
      "datastore.read",
      "mssql-database-protection-group.read",
      "mssql-database.read",
      "mssql-instance-snapshot.read",
      "mssql-instance.read",
      "protection-job.read",
      "virtual-machine-protection-group.read",
      "virtual-machine.read",
    ],
    src: atlasSrc,
    subscriptions: ["VM_PROTECTION"],
    title: "titleAtlas",
    type: "frame",
    feedbackLink:
      "https://www.hpe.com/ww/feedback-hpegreenlake-backupandrecovery",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["atlas"],
      src: atlasSrc,
    }),
  },
  atlasRop: {
    basePath: extractBasePath(atlasSrc),
    key: "atlasRop",
    navPath: "/backup-and-recovery-support",
    path: "/backup-and-recovery-support/*",
    resources: ["glbr-support.read"],
    src: atlasSrc,
    title: "titleAtlasRop",
    type: "frame",
    feedbackLink:
      "https://www.hpe.com/ww/feedback-hpegreenlake-backupandrecovery",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["atlas"],
      src: atlasSrc,
    }),
  },
  audit: {
    basePath: extractBasePath(auditSrc),
    flag: "shell-enable-audit-1",
    key: "audit",
    navPath: "/audit-log",
    path: "/audit-log/*",
    resources: ["audit-event.read"],
    src: auditSrc,
    title: "titleAudit",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["audit"],
      src: auditSrc,
    }),
  },
  "block-arcus": {
    basePath: extractBasePath(primeraSrc),
    key: "block-arcus",
    navPath: "/block/volumes/device-type4",
    path: "/block/volumes/device-type4/*",
    src: primeraSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block-primera"],
      src: primeraSrc,
    }),
  },
  block: {
    basePath: extractBasePath(blockSrc),
    key: "block",
    navPath: "/block/dashboard",
    path: "/block/dashboard/*",
    resources: [
      "host-initiator.read",
      "host-initiator-group.read",
      "volume.read",
      "volume-set.read",
    ],
    src: blockSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block"],
      src: blockSrc,
    }),
  },
  "block-data-access": {
    basePath: extractBasePath(dataAccessSrc),
    key: "block-data-access",
    navPath: "/block/data-access",
    path: "/block/data-access/*",
    src: dataAccessSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["data-access"],
      src: dataAccessSrc,
    }),
  },
  "block-edgestack": {
    basePath: extractBasePath(nimbleSrc),
    key: "block-edgestack",
    navPath: "/block/volumes/device-type3",
    path: "/block/volumes/device-type3/*",
    src: nimbleSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block-nimble"],
      src: nimbleSrc,
    }),
  },
  "block-fleet": {
    basePath: extractBasePath(fleetSrc),
    key: "block-fleet",
    navPath: "/block",
    path: "/block/*",
    src: fleetSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block-fleet"],
      src: fleetSrc,
    }),
  },
  "block-nimble": {
    basePath: extractBasePath(nimbleSrc),
    key: "block-nimble",
    navPath: "/block/volumes/device-type2",
    path: "/block/volumes/device-type2/*",
    src: nimbleSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block-nimble"],
      src: nimbleSrc,
    }),
  },
  "block-primera": {
    basePath: extractBasePath(primeraSrc),
    key: "block-primera",
    navPath: "/block/volumes/device-type1",
    path: "/block/volumes/device-type1/*",
    src: primeraSrc,
    title: "titleBlock",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["block-primera"],
      src: primeraSrc,
    }),
  },
  cloudPhysics: {
    key: "cloudPhysics",
    navPath: "https://app.cloudphysics.com/",
    title: "titleCloudPhysics",
    type: "external",
  },
  "cluster-status": {
    basePath: extractBasePath(statusSrc),
    key: "cluster-status",
    navPath: "/cluster-status",
    path: "/cluster-status/*",
    src: statusSrc,
    title: "titleClusterStatus",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["cluster-status"],
      src: statusSrc,
    }),
  },
  dataObservability: {
    basePath: extractBasePath(dataObservabilitySrc),
    flag: "dscc-data-observability",
    key: "dataObservability",
    navPath: "/data-observability",
    path: "/data-observability/*",
    resources: ["data-observability.read"],
    src: dataObservabilitySrc,
    title: "titleDataObservability",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["data-observability"],
      src: dataObservabilitySrc,
    }),
  },
  dualAuth: {
    basePath: extractBasePath(dualAuthSrc),
    key: "dualAuth",
    navPath: "/dual-authorization",
    path: "/dual-authorization/*",
    resources: ["customer-setting.read", "dual-auth-operation.read"],
    src: dualAuthSrc,
    title: "titleDualAuth",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dual-auth"],
      src: dualAuthSrc,
    }),
  },
  email: {
    basePath: extractBasePath(emailSrc),
    flag: "shell-enable-email-notifications-1",
    key: "email",
    navPath: "/email",
    path: "/email/*",
    src: emailSrc,
    title: "titleEmail",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths.email,
      src: emailSrc,
    }),
  },
  "file-manager": {
    basePath: extractBasePath(fileManagerSrc),
    flag: "shell-enable-file-storage-1",
    key: "file-manager",
    navPath: "/file-manager",
    path: "/file-manager/*",
    resources: [
      "file-group.read",
      "file-protection-policy.read",
      "file-share.read",
      "file-share-metrics-history.read",
      "file-share-snapshot.read",
      "file-share-setting.read",
      "file-tenant.read",
      "file-user.read",
    ],
    src: fileManagerSrc,
    title: "titleFileManager",
    type: "frame",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-filestorage",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["file-manager"],
      src: fileManagerSrc,
    }),
  },
  "dom-apollo-object": {
    basePath: extractBasePath(apolloObjectSrc),
    key: "dom-apollo-object",
    navPath: "/data-ops-manager/storage-systems/device-type5",
    path: "/data-ops-manager/storage-systems/device-type5/*",
    src: apolloObjectSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-apollo-object"],
      src: apolloObjectSrc,
    }),
  },
  "dom-arcus": {
    basePath: extractBasePath(primeraSrc),
    key: "dom-arcus",
    navPath: "/data-ops-manager/storage-systems/device-type4",
    path: "/data-ops-manager/storage-systems/device-type4/*",
    src: primeraSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-primera"],
      src: primeraSrc,
    }),
  },
  "dom-data-access": {
    basePath: extractBasePath(dataAccessSrc),
    key: "dom-data-access",
    navPath: "/data-ops-manager/data-access",
    path: "/data-ops-manager/data-access/*",
    src: dataAccessSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["data-access"],
      src: dataAccessSrc,
    }),
  },
  "dom-edgestack": {
    basePath: extractBasePath(nimbleSrc),
    key: "dom-edgestack",
    navPath: "/data-ops-manager/storage-systems/device-type3",
    path: "/data-ops-manager/storage-systems/device-type3/*",
    src: nimbleSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-nimble"],
      src: nimbleSrc,
    }),
  },
  "dom-fleet": {
    basePath: extractBasePath(fleetSrc),
    key: "dom-fleet",
    navPath: "/data-ops-manager",
    path: "/data-ops-manager/*",
    resources: [
      "host-initiator.read",
      "host-initiator-group.read",
      "storage-system.read",
      "controller.read",
      "port.read",
      "shelf.read",
      "disk.read",
      "storage-pool.read",
      "volume.read",
      "restore-point.read",
      "sds-system.read",
      "system-settings.read",
      "volume-set.read",
    ],
    src: fleetSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-fleet"],
      src: fleetSrc,
    }),
  },
  "dom-file": {
    basePath: extractBasePath(fileDomSrc),
    key: "dom-file",
    navPath: "/data-ops-manager/storage-systems/device-type6",
    path: "/data-ops-manager/storage-systems/device-type6/*",
    src: fileDomSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-file"],
      src: fileDomSrc,
    }),
  },
  "dom-homefleet": {
    basePath: extractBasePath(homefleetSrc),
    key: "dom-homefleet",
    navPath: "/data-ops-manager/storage-systems/device-type7",
    path: "/data-ops-manager/storage-systems/device-type7/*",
    src: homefleetSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-homefleet"],
      src: homefleetSrc,
    }),
  },
  "dom-nimble": {
    basePath: extractBasePath(nimbleSrc),
    key: "dom-nimble",
    navPath: "/data-ops-manager/storage-systems/device-type2",
    path: "/data-ops-manager/storage-systems/device-type2/*",
    src: nimbleSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-nimble"],
      src: nimbleSrc,
    }),
  },
  "dom-objectStorage": {
    basePath: extractBasePath(objectStorageSrc),
    key: "dom-objectStorage",
    navPath: "/data-ops-manager/object",
    path: "/data-ops-manager/object/*",
    src: objectStorageSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-objectStorage"],
      src: objectStorageSrc,
    }),
  },
  "dom-primera": {
    basePath: extractBasePath(primeraSrc),
    key: "dom-primera",
    navPath: "/data-ops-manager/storage-systems/device-type1",
    path: "/data-ops-manager/storage-systems/device-type1/*",
    src: primeraSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-primera"],
      src: primeraSrc,
    }),
  },
  "dom-sds": {
    basePath: extractBasePath(sdsSrc),
    key: "dom-sds",
    navPath: "/data-ops-manager/cloud",
    path: "/data-ops-manager/cloud/*",
    src: sdsSrc,
    title: "titleDom",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["dom-sds"],
      src: sdsSrc,
    }),
  },
  groups: {
    basePath: extractBasePath(groupsSrc),
    flag: "shell-enable-groups-1",
    key: "groups",
    navPath: "/groups",
    path: "/groups/*",
    resources: ["group.read"],
    src: groupsSrc,
    title: "titleGroups",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["groups"],
      src: groupsSrc,
    }),
  },
  intentBasedProvisioning: {
    key: "intentBasedProvisioning",
    navPath: "/block/volumes?createVolume=true",
    resources: ["volume.create"],
    title: "titleIntentBasedProvisioning",
    type: "internal",
    feedbackLink: "https://www.hpe.com/ww/feedback-hpegreenlake-blockstorage",
  },
  issues: {
    basePath: extractBasePath(issuesSrc),
    key: "issues",
    navPath: "/issues",
    path: "/issues/*",
    src: issuesSrc,
    title: "titleIssues",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["issues"],
      src: issuesSrc,
    }),
  },
  kubernetesService: {
    basePath: extractBasePath(kubernetesServiceSrc),
    flag: "ezmeral-kubernetes-service",
    key: "kubernetesService",
    navPath: "/k8s-svc",
    path: "/k8s-svc/*",
    resources: ["virtual-machine.read"],
    src: kubernetesServiceSrc,
    title: "titleKubernetesService",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["kubernetesService"],
      src: kubernetesServiceSrc,
    }),
  },
  objectStorage: {
    basePath: extractBasePath(objectStorageSrc),
    flag: "shell-enable-objects-1",
    key: "objectStorage",
    navPath: "/object-storage",
    path: "/object-storage/*",
    resources: ["bucket.read"],
    src: objectStorageSrc,
    title: "titleObjectStorage",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths.objectStorage,
      src: objectStorageSrc,
    }),
  },
  pcai: {
    basePath: extractBasePath(pcaiSrc),
    flag: "shell-enable-private-cloud-ai",
    key: "pcai",
    navPath: "/private-cloud-ai",
    path: "/private-cloud-ai/*",
    src: pcaiSrc,
    title: "titlePCAI",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths.pcai,
      src: pcaiSrc,
    }),
  },
  pcbe: {
    basePath: extractBasePath(pcbeSrc),
    key: "pcbe",
    navPath: "/private-cloud-business-edition",
    path: "/private-cloud-business-edition/*",
    src: pcbeSrc,
    title: "titlePCBe",
    type: "frame",
    feedbackLink:
      "https://www.hpe.com/ww/feedback-hpegreenlake-privatecloudbusinessedition",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths.pcbe,
      src: pcbeSrc,
    }),
  },
  search: {
    basePath: extractBasePath(searchSrc),
    key: "search",
    navPath: "/search",
    path: "/search/*",
    src: searchSrc,
    title: "titleSearch",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["search"],
      src: searchSrc,
    }),
  },
  secrets: {
    basePath: extractBasePath(secretsSrc),
    key: "secrets",
    navPath: "/secrets",
    path: "/secrets/*",
    resources: ["secret.read"],
    src: secretsSrc,
    title: "titleSecrets",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["secrets"],
      src: secretsSrc,
    }),
  },
  setup: {
    basePath: extractBasePath(setupSrc),
    key: "setup",
    navPath: "/setup",
    path: "/setup/*",
    resources: ["blueprint.read", "device.read"],
    src: setupSrc,
    title: "titleSetup",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["setup"],
      src: setupSrc,
    }),
  },
  sfm: {
    basePath: extractBasePath(sfmSrc),
    flag: "shell-enable-sfm-1",
    key: "sfm",
    navPath: "/storage-fabric-management",
    path: "/storage-fabric-management/*",
    resources: [
      "controller.read",
      "datacenter.read",
      "host-initiator.read",
      "host-initiator-group.read",
      "port.read",
      "sfm-switch.read",
      "storage-fabric-report.read",
      "storage-fabric-topology.read",
      "storage-system.read",
    ],
    src: sfmSrc,
    subscriptions: ["FABMGMT"],
    title: "titleSFM",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["sfm"],
      src: sfmSrc,
    }),
  },
  shapeshifter: {
    basePath: extractBasePath(shapeshifterSrc),
    flag: "shell-enable-shapeshifter-1",
    key: "shapeshifter",
    navPath: "/shapeshifter",
    path: "/shapeshifter/*",
    src: shapeshifterSrc,
    title: "titleShapeshifter",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["shapeshifter"],
      src: shapeshifterSrc,
    }),
  },
  tasks: {
    basePath: extractBasePath(tasksSrc),
    key: "tasks",
    navPath: "/tasks",
    path: "/tasks/*",
    resources: ["task.read"],
    src: tasksSrc,
    title: "titleTasks",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["tasks"],
      src: tasksSrc,
    }),
  },
  vcf: {
    key: "vcf",
    flag: (userLDFlags) => {
      const flagValue = userLDFlags["dscc-vmware-cloud-foundation"]
      return Array.isArray(flagValue) && flagValue.length > 0
    },
    navPath: "/vmware-cloud-foundation",
    path: "/vmware-cloud-foundation/*",
    title: "titleVCF",
    type: "internal",
  },
  webhooks: {
    basePath: extractBasePath(webhooksSrc),
    flag: "dscc-webhooks-service",
    key: "webhooks",
    navPath: "/webhooks",
    path: "/webhooks/*",
    resources: ["webhook.read"],
    src: webhooksSrc,
    title: "titleWebhooks",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["webhooks"],
      src: webhooksSrc,
    }),
  },
  unauthorized: {
    key: "unauthorized",
    navPath: "/unauthorized",
    path: "/unauthorized",
    type: "internal",
  },
  zerto: {
    basePath: extractBasePath(zertoSrc),
    flag: "shell-enable-zerto-1",
    key: "zerto",
    navPath: "/disaster-recovery",
    path: "/disaster-recovery/*",
    src: zertoSrc,
    title: "titleZerto",
    type: "frame",
    allowedPaths: getAllowedPaths({
      paths: allowedPaths["zerto"],
      src: zertoSrc,
    }),
  },
}
