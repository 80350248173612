// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import AuthService from "./oauth/auth-service"
import { AuthZPermissions } from "./utils/constants"
import { env } from "./utils/env"

const getJWT = async () => {
  const user = await AuthService.getUser()
  return user?.access_token
}

/**
 *
 * @param {Object} params
 * @param {string} [params.method=GET] - fetch method, e.g. GET or POST
 * @param {boolean} [params.requireAuth=true] - include Authorization header with bearer token
 * @param {AbortSignal} [params.signal] - signal object used to potentially abort a request
 * @returns {Promise<RequestInit>} - Promise resolving to a fetch RequestInit object
 */
const fetchOptions = async ({
  method = "GET",
  requireAuth = true,
  signal,
} = {}) => {
  return {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(requireAuth && { Authorization: `Bearer ${await getJWT()}` }),
    },
    signal,
  }
}

export const createClient = (baseURL) => {
  const ENDPOINTS = {
    ACCESS_CONTROLS: `${baseURL}/api/v1/access-controls`,
    SUBSCRIPTIONS: `${baseURL}/api/v1/subscriptions`,
    WORKSPACES: `${baseURL}/data-services/v1alpha1/workspaces`,
  }

  return {
    async getAccessControls({ signal } = {}) {
      const options = await fetchOptions({ signal })
      const params = new URLSearchParams(
        AuthZPermissions.map((p) => ["permission", p])
      ).toString()
      const response = await fetch(
        `${ENDPOINTS.ACCESS_CONTROLS}?${params}`,
        options
      )
      if (!response.ok) {
        throw new Error("Error fetching access-controls")
      }
      return response.json()
    },

    async getSubscriptions({ signal } = {}) {
      const options = await fetchOptions({ signal })
      const response = await fetch(ENDPOINTS.SUBSCRIPTIONS, options)
      if (!response.ok) {
        throw new Error("Error fetching subscriptions")
      }
      return response.json()
    },

    async getWorkspace({ signal } = {}) {
      const options = await fetchOptions({ signal })
      const response = await fetch(ENDPOINTS.WORKSPACES, options)
      if (!response.ok) {
        throw new Error("Error fetching workspace")
      }
      return response.json()
    },
  }
}

export const api = createClient(env.REACT_APP_API_URL)
