// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useQuery } from "@tanstack/react-query"
import { api } from "../api"

/**
 * Fetch the current workspace name.
 */
export default function useWorkspace() {
  const workspaceQuery = useQuery({
    queryKey: ["workspace"],
    queryFn: api.getWorkspace,
  })

  const workspace = workspaceQuery.data?.items[0] ?? {}

  return {
    loading: workspaceQuery.isLoading,
    workspace,
  }
}
