// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import { useLDIdentify } from "../contexts/launchdarkly-context"
import { isAccessible } from "../utils/accessible"

export default function FeatureFlag({
  children,
  fallbackComponent = null,
  requiredLDFlag = "",
}) {
  const userLDFlags = useFlags()
  const { hasFetchedUserFlags } = useLDIdentify()
  const { hasLDAccess } = isAccessible({
    userLDFlags,
    requiredLDFlag,
  })

  const hasLDGate =
    typeof requiredLDFlag === "function" || requiredLDFlag !== ""

  // if LDFlags are required stay on page until flags are fetched
  if (!hasFetchedUserFlags && hasLDGate) {
    return null
  }

  // redirect if flags are fetched and user does not have necessary flags
  if (hasFetchedUserFlags && !hasLDAccess) {
    return <>{fallbackComponent}</>
  }

  return children
}
