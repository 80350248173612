// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { NotificationsPlugin } from "@storage/react-notifications-plugin"
import { Box, Button } from "grommet"
import { Notification } from "grommet-icons"
import { Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuthZ, useAuthToken } from "../hooks"
import { env } from "../utils/env"
import { getTestIdAttribute, header } from "../utils/test-ids"

export default function NotificationsButton() {
  const token = useAuthToken()
  const { permissions } = useAuthZ()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const showNotificationsPlugin =
    token &&
    (permissions.includes("issue.read") ||
      permissions.includes("announcement.read"))

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        {showNotificationsPlugin && (
          <NotificationsPlugin
            alignment="bottom-end"
            dataTestId={header.notifications.plugin}
            offset={5}
            baseUrl={env.REACT_APP_API_URL}
            authToken={token}
            showIssues={permissions.includes("issue.read")}
            showAnnouncements={permissions.includes("announcement.read")}
            onAnnouncementsNotificationsViewDetailsClicked={() =>
              navigate("/announcements")
            }
            onIssuesNotificationsViewDetailsClicked={() => navigate("/issues")}
          >
            <Button
              slot="target"
              icon={<Notification size="medium" />}
              tip={{
                content: (
                  <Box {...getTestIdAttribute(header.notifications.tip)}>
                    {t("tipNotifications")}
                  </Box>
                ),
              }}
              {...getTestIdAttribute(header.notifications.icon)}
            />
          </NotificationsPlugin>
        )}
      </Suspense>
    </ErrorBoundary>
  )
}
