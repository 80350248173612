// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Identify, identify } from "@amplitude/analytics-browser"
import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { isInternal } from "../analytics"
import authService from "./auth-service"
import { ACCOUNT_KEY, REDIRECT_URI_KEY } from "./constants"
import { useLDIdentify } from "../contexts/launchdarkly-context"
import { ONPREM_API_CERT_KEY } from "../utils/constants"
import { isONPREM } from "../utils/env"

export function LoginRedirect({ children }) {
  const { ldIdentify } = useLDIdentify()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // ensure user is logged in
  useEffect(() => {
    async function loadUser() {
      const user = await authService.getUser()

      if (user) {
        setIsAuthenticated(true)
      } else {
        // store destination url (if not already present) so it can be loaded after authentication
        if (!sessionStorage.getItem(REDIRECT_URI_KEY)) {
          sessionStorage.setItem(
            REDIRECT_URI_KEY,
            window.location.href.replace(window.location.origin, "")
          )
        }

        // if we have account in localStorage, login with those values
        // e.g. when opening a new tab from an existing session
        let account
        try {
          account = JSON.parse(localStorage.getItem(ACCOUNT_KEY))
        } catch (e) {
          console.error(e)
        }
        const cid = account?.applicationCustomerId ?? null
        const workspaceId = account?.workspaceId ?? null
        if (cid) {
          return authService.login({ cid, workspaceId })
        }

        navigate("/login", { replace: true })
      }
    }

    // check for onprem cert acceptance before auth flow
    if (isONPREM() && !sessionStorage.getItem(ONPREM_API_CERT_KEY)) {
      if (!sessionStorage.getItem(REDIRECT_URI_KEY)) {
        sessionStorage.setItem(
          REDIRECT_URI_KEY,
          window.location.href.replace(window.location.origin, "")
        )
      }
      navigate("/accept-certificate", { replace: true })
    } else if (!isAuthenticated) {
      loadUser()
    }
  }, [isAuthenticated, navigate])

  useEffect(() => {
    // only run if the user has been authenticated
    if (isAuthenticated) {
      ldIdentify()
    }
  }, [isAuthenticated, ldIdentify])

  // set amplitude user properties post-login
  useEffect(() => {
    async function ampIdentify() {
      const user = await authService.getUser()
      const event = new Identify()
      event.setOnce("isInternal", isInternal(user?.profile?.email))
      identify(event)
    }

    if (isAuthenticated) {
      ampIdentify()
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return children ? children : <Outlet />
}
