// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import { lazy, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useAuthToken } from "../hooks"
import { env } from "../utils/env"

const TaskBannerPlugin = lazy(() => import("./task-banner-plugin"))

export default function TaskBanner() {
  const { "shell-enable-rtm-1": shellEnableRTM1 } = useFlags()
  const token = useAuthToken()

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        {token && (
          <TaskBannerPlugin
            authToken={token}
            baseUrl={env.REACT_APP_API_URL}
            rtm={shellEnableRTM1}
          />
        )}
      </Suspense>
    </ErrorBoundary>
  )
}
