// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import { useLDIdentify } from "../contexts/launchdarkly-context"
import { useSubscriptions } from "../hooks"
import { isAccessible } from "../utils/accessible"

export default function FeatureFlagOrSubscription({
  children,
  fallbackComponent = null,
  requiredLDFlag = "",
  requiredSubscriptions = [],
}) {
  const userLDFlags = useFlags()
  const { hasFetchedUserFlags } = useLDIdentify()
  const { loading: subscriptionsLoading, subscriptions: userSubscriptions } =
    useSubscriptions()
  const { hasLDAccess, hasSubscriptionAccess } = isAccessible({
    userLDFlags,
    requiredLDFlag,
    userSubscriptions,
    requiredSubscriptions,
  })

  const hasLDGate =
    typeof requiredLDFlag === "function" || requiredLDFlag !== ""

  const hasSubscriptionGate =
    typeof requiredSubscriptions === "function" ||
    requiredSubscriptions.length > 0

  const loading =
    (hasLDGate && !hasFetchedUserFlags) ||
    (hasSubscriptionGate && subscriptionsLoading)

  // if LDFlags or Subscriptions are required stay on page until fetched
  if (loading && (hasLDGate || hasSubscriptionGate)) {
    return null
  }

  // redirect if LD and subscriptions finished loading AND
  // user does not have access through either
  if (!loading && !hasLDAccess && !hasSubscriptionAccess) {
    return <>{fallbackComponent}</>
  }

  return children
}
