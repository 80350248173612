// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

/*
 * isAccessible() is used to determine if an element (typically, a launchpad tile or a route) should be accessible by
 * the user, given the enablements (authz permissions,  subscriptions, and LD flags) associated with the element, and the
 * user's corresponding enablements.  The element is accessible if it is not restricted to the user due to authz
 * permissions, and if either the element is associated with an LD flag and the user has that LD flag, or if the user is
 * not restricted due to subscriptions.  The subscription portion of that logic only applies in cases where there are no
 * explicit LD flags associated with the element or where the user has explicit subscription privileges (as apposed to
 * the user having "default" subscription privileges due to the element not being associated with any subscriptions).
 *
 * Element enablements are specified in an object that contains a list of authz permissions, a list of available
 * a list of subscriptions, and a LD flag. User enablements are specified in an object that contains a list of user's authz
 * permissions, an associative array of the user's subscriptions, and the user's LD flags associative array.
 *
 * In addition to the accessible decision described above, this function also returns the permission restriction and
 * subscripton restriction decisions, as a convenience to clients that require those sub-decisions to adjust their
 * behavior.  For example, tile logic might use different button labels and links based on if a user has a subscription
 * enablement or not.
 */

import { isDSCC } from "./env"

/**
 * User access decision.
 * @typedef {Object} Accessible
 * @property {boolean} hasTileAccess Indicates whether the user has access to the Tile
 * @property {boolean} hasPermissionAccess Indicates whether the user has valid permisssions or permissions are not required
 * @property {boolean} hasSubscriptionAccess Indicates whether the user has valid subscriptions or subscriptions are not required
 */

/**
 * @callback requiredLDFlagsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @returns {boolean} User has required LD flags
 */

/**
 * @callback requiredSubscriptionsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @param {Object.<string, boolean>} userSubscriptions User's allowed subscriptions
 * @returns {boolean} User has required subscriptions
 */

/**
 * Test if an element should be accessible by a user.
 * @param {Object} Enablements
 * @param {Object.<string, any>} [Enablements.userLDFlags={}] User's LaunchDarkly flags
 * @param {string|requiredLDFlagsFn} [Enablements.requiredLDFlag=""] LaunchDarkly flag associated with the element
 * @param {string[]} [Enablements.userPermissions=[]] User's authz permissions
 * @param {string[]} [Enablements.requiredPermissions=[]] Authz permissions allowing access to the element
 * @param {Object.<string, boolean>} [Enablements.userSubscriptions={}] User's allowed subscriptions
 * @param {string[]|requiredSubscriptionsFn} [Enablements.requiredSubscriptions=[]] Subscriptions allowing access to the element
 * @return {Accessible} User access decision
 */
export function isAccessible({
  userLDFlags = {},
  requiredLDFlag = "",
  userPermissions = [],
  requiredPermissions = [],
  userSubscriptions = {},
  requiredSubscriptions = [],
} = {}) {
  /**
   * The element is Permission Restricted if it requires authz permissions from the user.
   */
  const isPermissionRestricted = requiredPermissions.length > 0

  /**
   * The element is Subscription Restricted if it requires subscriptions from the user.
   */
  const isSubscriptionRestricted =
    isDSCC() &&
    (typeof requiredSubscriptions === "function" ||
      requiredSubscriptions.length > 0)

  /**
   * The element is LD Restricted if it requires a LaunchDarkly flag to be enabled for the user.
   */
  const isLDRestricted =
    typeof requiredLDFlag === "function" || requiredLDFlag !== ""

  /**
   * The element has no restrictions if it isn't blocked by subscriptions, or Launchdarkly.
   * The element may or may not be restricted by authz permissions.
   */
  const hasNoRestrictions = !isSubscriptionRestricted && !isLDRestricted

  /**
   * The user is considered to have valid authz permissions if
   * the user has at least one authz permission that is required by the element.
   */
  const hasValidPermissions =
    isPermissionRestricted &&
    requiredPermissions.some((p) => userPermissions.includes(p))

  /**
   * The user is considered to have valid subscriptions if
   * the user has at least one subscription that is required by the element.
   */
  const hasValidSubscriptions =
    isSubscriptionRestricted &&
    (typeof requiredSubscriptions === "function"
      ? requiredSubscriptions(userLDFlags, userSubscriptions)
      : requiredSubscriptions.some((s) => !!userSubscriptions[s]))

  /**
   * The user is considered to have valid flag enabled if
   * the user has the required flag enabled.
   */
  const hasValidFlag =
    isLDRestricted &&
    (typeof requiredLDFlag === "function"
      ? requiredLDFlag(userLDFlags)
      : !!userLDFlags[requiredLDFlag])

  /**
   * The element is accessible if
   * the user has valid authz permissions or the element is not restricted by permissions
   * AND either the user has a valid subscription
   * or the flag enabled for them
   * or the element is not restricted by either of the three
   */
  const hasTileAccess =
    (hasValidPermissions || !isPermissionRestricted) &&
    (hasValidSubscriptions || hasValidFlag || hasNoRestrictions)

  return {
    hasTileAccess,
    hasPermissionAccess: hasValidPermissions || !isPermissionRestricted,
    hasSubscriptionAccess: hasValidSubscriptions || !isSubscriptionRestricted,
    hasLDAccess: hasValidFlag || !isLDRestricted,
  }
}
