// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import { flagManager } from "./flag-manager"
import { initShell } from "./init"
import i18n from "./i18n-config/i18n"
import "./index.css"
import { env, extractOrigin } from "./utils/env"

async function bootstrapMirage() {
  try {
    const { createMockServerForCypress, createMockServerForDev } = await import(
      "./mirage/mock-server"
    )
    if (window.Cypress) {
      createMockServerForCypress()
    } else {
      createMockServerForDev()
    }
  } catch (err) {
    console.error(err)
  }
}

function initGlpHeader() {
  const headerNode = document.querySelector("greenlake-header")
  if (!headerNode) {
    return
  }
  headerNode.platform = "publicDefault"
  headerNode.language = i18n.language
  headerNode.linkbaseurl = extractOrigin(env.REACT_APP_CCS_LOGIN)
}

;(async () => {
  if (env.REACT_APP_PROXY_MIRAGE === "true") {
    await bootstrapMirage()
  }

  // initialize Launchdarkly client and fetch anonymous flags
  const fm = await flagManager.init()
  initShell({ flagClient: fm, window })
  initGlpHeader()

  const root = createRoot(document.getElementById("root"))
  root.render(
    <StrictMode>
      <App ldClient={fm.client} />
    </StrictMode>
  )
})()
