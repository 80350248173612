// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useEffect, useState } from "react"
import { TOKEN_REFRESH_EVENT_TYPE } from "../events"
import authService from "../oauth/auth-service"
import useUser from "./use-user"

export default function useAuthToken() {
  const user = useUser()
  const [token, setToken] = useState()

  useEffect(() => {
    if (user?.access_token) {
      setToken(user.access_token)
    }
  }, [user?.access_token])

  useEffect(() => {
    const ac = new AbortController()
    const listener = async () => {
      const user = await authService.getUser()
      if (user?.access_token && !ac.signal.aborted) {
        setToken(user.access_token)
      }
    }
    window.addEventListener(TOKEN_REFRESH_EVENT_TYPE, listener)
    return () => {
      ac.abort()
      window.removeEventListener(TOKEN_REFRESH_EVENT_TYPE, listener)
    }
  }, [])

  return token
}
